import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import BannerImage from '../components/Page/BannerImage'
import ChildrensFestival from '../components/Page/ChildrensFestival'
import EmbedVideo from '../components/EmbedVideo'

const childrensFestivalPage = ({data}) => {
	return (
		<Layout>
			<BannerImage image={data.file.childImageSharp.gatsbyImageData} />
			<section className="bg-boswell-handwriting pt-12 pb-16">
				{/*<p className={`text-3xl px-12`}>Please <Link to={`/2021/childrens-festival-box-office`} className={`link`}>register through the box office</Link> for all events.</p>*/}
				<div className={`flex flex-row items-start flex-wrap `}>
					<div className="md:w-1/2">
						<ChildrensFestival />
					</div>
					<div className={`p-4 md:p-12 w-full md:w-1/2`}>
						<EmbedVideo src={`https://www.youtube-nocookie.com/embed/ZHxSbGy9taQ`} title={`2022 Children's Festival Promo`} />
						<div className={`p-6`} />
						<EmbedVideo src={`https://www.youtube-nocookie.com/embed/_I3LwL0JY6w`} title={`2022 Children's Festival Promo`} />
						<div className={`p-6`} />
						<EmbedVideo src={`https://www.youtube-nocookie.com/embed/lBRFI2UH3lQ`} title={`2021 Children's Festival Promo`} />
					</div>
				</div>
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "childrens-festival/Childrens festival page 2023.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
  programmeChildrensImg: file(
    relativePath: {eq: "Boswell-Schools-and-Family-Online-2021-Cover-001.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 300, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
    }
  }
}
`

export default childrensFestivalPage
